<template>
    <div class="container-fluid">
        <div class="mainBox" style="margin-top:-1.2rem;">
            <div class="row bgDark pb-4">
                <div class="col-md-6 order-md-2"><img src="@/assets/images/concat2.png" class="w-100"></div>
                <div class="col-md-6 order-md-1 con">
                    <div class="time display-2 mt-3 mt-md-0">
                        <div>{{Hours}}</div>
                        <div class="breath"> : </div>
                        <div>{{Minutes}}</div>
                    </div>
                    <h2 class="mt-3">中国.深圳</h2>
                    <h4 class="mt-3">深圳宝安区金湾大道聚豪天下别墅 E-85栋</h4>
                    <div class="row">
                        <div class="col-md-10">
                            <h4 class="mt-3">No. Building E-85, Juhaotianxia Villa, Jinwan Avenue,Baoan District, Shenzhen</h4>
                        </div>
                    </div>
                    <h4 class="colorRed mt-2 mt-md-5">Contact<br>商务与合作事务尽可取得联系</h4>
                    <div class="row">
                        <div class="col-md-10">
                            <div class="row mt-3">
                                <div class="col-md-4"><img src="@/assets/images/concat1.png" class="w-100"></div>
                                <div class="col-8 col-md-4 align-self-end mt-2">
                                    <div class="d-flex align-items-baseline mt-2">
                                        <h2>马 嵘</h2>
                                        <h5>MONICA MA</h5>
                                    </div>
                                    <h4 class="mt-1">深圳BOB设计/总经理</h4>
                                    <h4 class="mt-1">135 0963 6346</h4>
                                    <h4 class="mt-1">635450917@qq.com</h4>
                                </div>
                                <div class="col-4 col-md-2 align-self-end mt-2"><img src="@/assets/images/concat3.png" class="w-100"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            text: 'contact',
            time: '',
            Hours: '',
            Minutes: '',
            Seconds: '',
        }
    },

    mounted() {
        this.getTime()
        setInterval(() => {
            this.getTime()
        }, 1000)
    },
    methods: {
        getTime() {
            var myDate = new Date();
            var Hours = myDate.getHours(); //获取当前小时数(0-23)
            var Minutes = myDate.getMinutes() < 10 ? '0' + myDate.getMinutes() : myDate.getMinutes(); //获取当前分钟数(0-59)
            var Seconds = myDate.getSeconds() < 10 ? '0' + myDate.getSeconds() : myDate.getSeconds();
            this.Hours = Hours
            this.Minutes = Minutes
            this.Seconds = Seconds
            this.time = Hours + ":" + Minutes + ":" + Seconds
        },

    },
    watch: {

    }
}
</script>
<style lang="scss" scoped>
.con {
    color: #ffffff;
}
.time{
  display:flex;
  align-items:center;
  text-align: center;
  &>div:nth-child(2){
    margin:0 1rem;
    margin-top:-0.8rem;
  }
}
.breath {

    animation: breath 1s infinite;
    margin-top:-0.5rem;
}

@keyframes breath {
    0% {
        opacity:1;
    }

    25% {
        opacity:0.3;
    }

    50% {
        opacity:1;
    }

    100% {
        opacity:1;
    }
}
</style>