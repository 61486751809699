import request from '@/utils/request'

// 查询合伙人列表
export function listPartner(query) {
  return request({
    url: '/public/partner/list',
    method: 'get',
    params: query
  })
}

// 查询合伙人列表
export function getCompany() {
  return request({
    url: '/public/company/info',
    method: 'get'
  })
}