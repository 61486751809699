var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"mainBox"},[_vm._m(0),_c('div',{staticClass:"itemWrap"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-10"},[_vm._m(1),_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-10"},[_c('div',[_vm._v(_vm._s(_vm.form.about))])])])])])]),_c('div',{staticClass:"itemWrap"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-10"},[_vm._m(2),_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-10"},[_c('div',[_vm._v(_vm._s(_vm.form.content))])])])])])]),_c('div',{staticClass:"itemWrap"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-10"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3 col-lg-2"},[_c('div',{staticClass:"text-center text-md-left colorRed mainTitle"},[_vm._m(3),_c('div',[_vm._v("任期: "+_vm._s(_vm.form.jobDate))])])]),_c('div',{staticClass:"col-md-3"},[_c('img',{staticClass:"w-100",attrs:{"src":_vm.baseUrl + _vm.form.jobPic}})]),_c('div',{staticClass:"col offset-md-1 mt-3 mt-md-0"},[_c('div',{staticClass:"jobContent",domProps:{"innerHTML":_vm._s(_vm.form.jobContent)}})])])])])]),_c('div',{staticClass:"itemWrap"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-10"},[_vm._m(4),_c('div',{staticClass:"row row-cols-2 row-cols-lg-5"},_vm._l((_vm.partnerList),function(item){return _c('div',{staticClass:"col mb-4"},[_c('div',{staticClass:"mb-2"},[_c('img',{staticClass:"w-100",attrs:{"src":_vm.baseUrl + item.pic}})]),_c('h4',{staticClass:"mt-3"},[_vm._v(_vm._s(item.name))]),_c('h6',{staticClass:"mt-2",staticStyle:{"white-space":"pre-line"},domProps:{"innerHTML":_vm._s(item.content)}})])}),0)])])]),_vm._m(5),_vm._m(6)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('img',{staticClass:"w-100",attrs:{"src":require("@/assets/images/about/a1.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center text-md-left colorRed mainTitle"},[_c('h4',[_vm._v("ABOUT BOB"),_c('br'),_vm._v("关于我们")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center text-md-left colorRed mainTitle"},[_c('h4',[_vm._v("WHAT CAN BE DONE"),_c('br'),_vm._v("能做什么")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',[_vm._v("FOUNDER"),_c('br'),_vm._v("首席设计师")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center text-md-left colorRed mainTitle"},[_c('h4',[_vm._v("DIRECTOR PARTNER"),_c('br'),_vm._v("董事合伙人")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"itemWrap"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-10"},[_c('div',{staticClass:"text-center text-md-left colorRed mainTitle"},[_c('h4',[_vm._v("COOPERATIVE PARTNER"),_c('br'),_vm._v("合作伙伴")])])])]),_c('div',{staticClass:"row"},[_c('img',{staticClass:"w-100",attrs:{"src":require("@/assets/images/about/a6.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"itemWrap"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-10"},[_c('div',{staticClass:"text-center text-md-left colorRed mainTitle"},[_c('h4',[_vm._v("WIN AWARD"),_c('br'),_vm._v("获得奖项")])])])]),_c('div',{staticClass:"row"},[_c('img',{staticClass:"w-100",attrs:{"src":require("@/assets/images/about/a7.png")}})])])
}]

export { render, staticRenderFns }