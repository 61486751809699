<template>
  <div class="container-fluid">
    <div class="mainBox">
      <div class="row">
        <div class="col-12"><img src="@/assets/images/about/a1.png" class="w-100"></div>
      </div>

      <div class="itemWrap">
        <div class="row justify-content-center">
          <div class="col-md-10 ">
            <div class="text-center text-md-left colorRed mainTitle"><h4>ABOUT BOB<br>关于我们</h4></div>
            <div class="row justify-content-center">
              <div class="col-md-10 "><div>{{form.about}}</div></div>
            </div>
          </div>
        </div>
      </div>

      <div class="itemWrap">
        <div class="row justify-content-center">
          <div class="col-md-10 ">
            <div class="text-center text-md-left colorRed mainTitle"><h4>WHAT CAN BE DONE<br>能做什么</h4></div>
            <div class="row justify-content-center">
              <div class="col-md-10 "><div>{{form.content}}</div></div>
            </div>
          </div>
        </div>
        
      </div>

      <div class="itemWrap">
        <div class="row justify-content-center">
          <div class="col-md-10 ">
            <div class="row">
              <div class="col-md-3 col-lg-2">
                <div class="text-center text-md-left colorRed mainTitle">
                  <h4>FOUNDER<br>首席设计师</h4>
                  <div>任期: {{form.jobDate}}</div>
                </div>
              </div>
              <div class="col-md-3"><img :src="baseUrl + form.jobPic" class="w-100"></div>
              <div class="col offset-md-1 mt-3 mt-md-0">
                  <!-- <h2 class="mb-1">智晓波</h2>
                  <h5>智小波/BOB</h5>
                  <h5>深圳BOB设计/首席设计师</h5>
                  <h5>疆道酒业CEO</h5>
                  <h5>Shenzhen BOB Design/Chief Designer</h5>
                  <h5>Lidao Wine Industry CEO</h5>
                  <h5><kbd>包装设计奥斯卡奖pentawards铂金奖获得者</kbd></h5>
                  <h5 class="mt-1"><kbd>2021德国IF金奖获得者</kbd></h5>
                  <h5>江西财经大学特聘研究生导师,国内多所高校特聘导师</h5>
                  <h5>MA创意大奖国际评审团评委</h5>
                  <h5>Adobe国际认证资深设计师</h5>
                  <h5>纽约创意指导俱乐部会员</h5>
                  <h5>亚太设计联盟资深会员</h5>
                  <h5>中外设计研究院研究员</h5>
                  <h5>深圳工业设计协会理事单位</h5>
                  <h5>获国际知名奖项100多项</h5> -->
                  <div v-html="form.jobContent" class="jobContent"></div>
              </div>
            </div>
          </div>
        </div>
        
      </div>


      <div class="itemWrap">
        <div class="row justify-content-center">
          <div class="col-md-10 ">
            <div class="text-center text-md-left colorRed mainTitle"><h4>DIRECTOR PARTNER<br>董事合伙人</h4></div>
            <div class="row row-cols-2 row-cols-lg-5">
              <div class="col mb-4" v-for="item in partnerList">
                <div class="mb-2">
                  <img :src="baseUrl + item.pic" class="w-100">
                </div>
                <h4 class="mt-3">{{item.name}}</h4>
                <h6 class="mt-2" style="white-space:pre-line;" v-html="item.content"></h6>
              </div>
              
            </div>
          </div>
        </div>
      </div>


      <div class="itemWrap">
        <div class="row justify-content-center">
          <div class="col-md-10 ">
            <div class="text-center text-md-left colorRed mainTitle"><h4>COOPERATIVE PARTNER<br>合作伙伴</h4></div>
          </div>
        </div>
        <div class="row"><img src="@/assets/images/about/a6.png" class="w-100"></div>
      </div>


      <div class="itemWrap">
        <div class="row justify-content-center">
          <div class="col-md-10 ">
            <div class="text-center text-md-left colorRed mainTitle"><h4>WIN AWARD<br>获得奖项</h4></div>
          </div>
        </div>
        <div class="row"><img src="@/assets/images/about/a7.png" class="w-100"></div>
      </div>





    </div>
  </div>
</template>
<script>
import { listPartner, getCompany} from "@/api/about";
export default {
  data() {
    return {
      text:'about',
      // 查询参数
      queryParams: {
          pageNum: 1,
          pageSize: 20

      },
      baseUrl: process.env.VUE_APP_BASE_API,
      partnerList: [],
      form:{
        about:'',
        content:'',
        jobDate:'',
        jobPic:'',
        jobContent:'',
      }
    }
  },

  computed: {
    
  },
   mounted() {
      this.getList();
      this.getCompanyInfo()

  },
  methods: {
     /** 查询作品列表 */
      getList() {
          this.loading = true;
          listPartner(this.queryParams).then(response => {
              this.partnerList = response.rows;
              this.loading = false;
          });
      },
      getCompanyInfo() {
          let _this = this
          _this.loading = true
          getCompany().then(response => {
              _this.loading = false
              let data = response.data
              console.log('response',response)
              _this.form = data;
          });
      }
  },
  watch: {
   
  }
}
</script>
<style>
  .jobContent>h5{
    line-height: 1.5;

  }
</style>
<style lang="scss" scoped>

  .itemWrap{
    color:#000000;
    padding:2rem 0;
    .mainTitle{
      padding-bottom:2rem;
    }
  }
</style>
