<template>
    <div class="mainBox">
        <div class="container-md mt-3 mb-3">
            <div class="row  align-items-center newItem cursor animated fadeInUp" v-for="(item,index) in newsList"  @click="toDetailPage(item)">
                <div class="col-md-6 order-md-2">
                    <div class="imgBox">
                        <img :src="baseUrl + item.pic">
                    </div>
                </div>
                <div class="col-md-6 order-md-1 mt-md-0 con">
                    <h4 class="showText-line-2">{{item.title}}</h4>
                    <div class="date mt-2 mb-2">{{item.publishTime}}</div>
                    <div class="des showText-line-3">{{item.summary}}</div>
                </div>
                <div class="col-md-12 order-3" v-if="index < newsList.length - 1">
                    <el-divider></el-divider>
                </div>
            </div>
            <div class="text-center">
                <div class="spinner-border text-secondary" role="status" v-if="loading">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import _ from 'lodash'
import { listNews, getNews } from "@/api/news";

export default {
    components: {},
    data() {
        return {
            text: 'news',


            loading: false,
            total: 0,
            loadingType: 'more',
            // 查询参数
            queryParams: {
                pageNum: 1,
                pageSize: 5

            },
            baseUrl: process.env.VUE_APP_BASE_API,
            newsList: []




        }
    },

    computed: {

    },
    mounted() {
        window.addEventListener("scroll", this.lazyLoading)
        this.getList();

    },
    methods: {
        /** 查询作品列表 */
        getList() {
            this.loading = true;
            listNews(this.queryParams).then(response => {
                this.newsList = response.rows;
                this.total = response.total;
                this.loadingType = this.newsList.length < this.total ? 'more' : 'momore'
                this.loading = false;
            });
        },
        /** 查询作品列表 */
        getMoreList() {
            this.queryParams.pageNum = this.queryParams.pageNum + 1
            this.loading = true;
            listNews(this.queryParams).then(response => {
                this.newsList = this.newsList.concat(response.rows);
                this.total = response.total;
                this.loadingType = this.newsList.length < this.total ? 'more' : 'momore'
                this.loading = false;
            });
        },
        lazyLoading(e) {
            //console.log(e)
            if (this.loadingType == 'momore') {
                return;
            }
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            const clientHeight = document.documentElement.clientHeight
            const scrollHeight = document.documentElement.scrollHeight
            const height = 300
            if (scrollTop + clientHeight >= scrollHeight - height) { //判断是否触底了
                if (!this.loading) {
                    this.getMoreList()
                }

            }

        },
        toDetailPage(item) {
            this.$router.push({ path: '/newsDetail?id=' + item.id })
        },

    },
    destroyed() {
        window.removeEventListener('scroll', this.lazyLoading)
    },
}
</script>
<style lang="scss" scoped>
.newItem {
    .con {
        margin-top: 2rem;

        h4 {
            color: #E7380C;
        }

        .date {
            color: #FF9C9C;
        }

        .des {}
    }

    .imgBox {
        width: 100%;
        padding-top: 50%;
        position: relative;
        overflow: hidden;

        img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            top: 0;
        }
    }
}
</style>