<template>
    <div class="container-fluid footer">
        <div class="row">
            <div class="col-12">
                <div class="row align-items-center align-items-md-start mb-md-5">
                    <div class="col-8">
                        <div class="d-flex align-items-baseline abUs">
                            <h2 class="colorRed font-weight-bold mr-2">About</h2>
                            <h5>深圳BOB设计</h5>
                        </div>
                    </div>
                    <div class="col-4 text-right d-flex justify-content-end">
                        <div><img src="@/assets/images/footer2.png" style="width:4rem"></div>
                        <div class="d-none d-xl-block text-center ml-md-5 ">
                            <div><img src="@/assets/images/phoneUrl.png" style="width:5rem"></div>
                            <div class="mt-lg-3">
                                <h6>手机版</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 text-center text-md-left mt-4">
                        <div class="subNav">
                            <a href="#/work">作品</a>
                            <span>|</span>
                            <a href="#/about">关于</a>
                            <span>|</span>
                            <a href="#/news">新闻</a>
                            <span>|</span>
                            <a href="#/contact">联系</a>
                            <span>|</span>
                            <a href="javascript:void(0)" @click="showViewer=true">加入</a>
                        </div>
                        <div class="address">新礼记(深圳)品牌科技有限公司</div>
                    </div>
                    <div class="col-md-6 text-center text-md-right mt-4">
                        <div class="toItem">
                            <template v-for="(item,index) in imagesList">
                                <el-popover v-if="index == 0" placement="top" width="200" trigger="click">
                                    <div><img src="@/assets/images/gzh.png" class="w-100"></div>
                                    <img slot="reference" class="list-img" :src="item.img">
                                </el-popover>
                                <img v-else class="list-img" :src="item.img" @click="jumpTo(item.url)">
                            </template>
                        </div>
                        <div class="mt-3"><a class="beian" href="https://beian.miit.gov.cn/" target="_blank">{{copyRight}}</a></div>
                    </div>
                </div>
            </div>
        </div>
        <el-image-viewer v-if="showViewer" :on-close="()=>{showViewer=false}" :url-list="jobList" />
    </div>
</template>
<script>
import { listJob, getJob } from "@/api/job";
export default {
    components: {
        'el-image-viewer': () => import('element-ui/packages/image/src/image-viewer')
    },
    data() {
        return {
            imagesList: [
                { id: 8, name:'微信', img: require('@/assets/images/footer8.png'), url: '' },
                { id: 3, name:'', img: require('@/assets/images/footer3.png'), url: '' },
                { id: 7, name:'新浪微博', img: require('@/assets/images/footer7.png'), url: '' },
                { id: 5, name:'抖音', img: require('@/assets/images/footer5.png'), url: 'https://www.douyin.com/user/MS4wLjABAAAAKHsXon-ERvPmOMYNHlvbrBIZpxgiXwDcuTR-ODUy1L9CtYZkAz4GL9KKJcRKDqg7' },
                { id: 6, name:'facebook', img: require('@/assets/images/footer6.png'), url: '' },
                { id: 1, name:'小红书', img: require('@/assets/images/footer1.png'), url: 'https://www.xiaohongshu.com/user/profile/641eebe400000000120116e0?xhsshare=CopyLink&appuid=5ac1ecda11be103195213003&apptime=1698042226' },
                { id: 4, name:'BE', img: require('@/assets/images/footer4.png'), url: 'https://www.behance.net/monicama5' },
                // { id: 9, name:'YOUTUBE', img: require('@/assets/images/footer4.png'), url: 'https://www.youtube.com/@SHENZHENBOBDESIGN' },
            ],
            baseUrl: process.env.VUE_APP_BASE_API,
            copyRight: '粤ICP备2023134842号-1',
            showViewer: false,
            jobList: [],
            // 查询参数
            queryParams: {
                pageNum: 1,
                pageSize: 50
            },
        }
    },

    mounted(){
        this.getList()
    },
    methods: {
        /** 查询招聘列表 */
        getList() {
            let _this = this
            _this.loading = true;
            listJob(_this.queryParams).then(response => {
                _this.jobList = response.rows.map(item=>{
                    return _this.baseUrl + item.pic
                });
                _this.loading = false;
            });
        },
        jumpTo(url) {
            if (url) {
                window.open(url)
            }
        },
        showJobModel() {

        }
    }
}
</script>
<style lang="scss" scoped>
.footer {
    background-color: #000;
    padding-top: 2rem;
    padding-bottom: 2rem;
    color: #a7a7a7;
    border-top: solid 2px #FFFFFF;



    .subNav a {
        color: #a7a7a7;
        font-size: 1.2rem
    }

    .subNav span {
        font-size: 1rem;
        padding: 0 10px;
    }

    .address {
        font-size: 1rem;
        padding-top: 0.5rem;
    }

    .toItem {
        img {
            width: 2rem;
            height: 2rem;
            margin-right: 0.5rem;
            cursor: pointer;

            &:hover {
                opacity: 0.8
            }
        }
    }
    .beian{
        color:#ffffff;
    }
}
</style>