import request from '@/utils/request'

// 查询招聘列表
export function listJob(query) {
  return request({
    url: '/public/job/list',
    method: 'get',
    params: query
  })
}

// 查询招聘详细
export function getJob(id) {
  return request({
    url: '/public/job/' + id,
    method: 'get'
  })
}

