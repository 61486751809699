import request from '@/utils/request'

// 查询作品列表
export function listWorks(query) {
  return request({
    url: '/public/works/list',
    method: 'get',
    params: query
  })
}

// 查询作品详细
export function getWorks(id) {
  return request({
    url: '/public/works/' + id,
    method: 'get'
  })
}

