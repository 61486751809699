<template>
    <div class="topBox">
        <b-navbar toggleable="lg" type="dark" variant="dark" fixed="top" :class="{hide:!topNavShow}">
            <div class="container-fluid">
                <b-navbar-brand><a href="#/work"><img src="@/assets/images/bob.png" style="width:6rem"></a></b-navbar-brand>
                <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
                <b-collapse id="nav-collapse" is-nav>
                    <!-- <b-navbar-nav>
                        <b-nav-item href="#" disabled>xxx</b-nav-item>
                    </b-navbar-nav> -->
                    <!-- Right aligned nav items -->
                    <b-navbar-nav class="ml-auto">
                        <b-nav-item href="#/work" :class="{ choose: current=='work' }">Work</b-nav-item>
                        <b-nav-item href="#/about" :class="{ choose: current=='about' }">About</b-nav-item>
                        <b-nav-item href="#/news" :class="{ choose: current=='news' }">News</b-nav-item>
                        <b-nav-item href="#/contact" :class="{ choose: current=='contact' }">Contact</b-nav-item>
                        <!-- <b-nav-item href="#/job" :class="{ choose: current=='job' }">Join</b-nav-item> -->
                    </b-navbar-nav>
                </b-collapse>
            </div>
        </b-navbar>
        <!-- <div class="d-none d-md-block">
            <div class="el-backtop" v-show="showToTop" style="right: 2rem; bottom: 40px;" @click="topTopBtn"><i class="el-icon-caret-top"></i></div>
        </div> -->
    </div>
</template>
<script>
export default {
    data() {
        return {
            current: null,
            showToTop: false,
            // 控制导航栏是否显示的变量
            topNavShow: true,
            // 声明一个数组存放比对数据
            scrollData: [],
            // 一个响应速度的参数，值越大响应越慢
            responseSpeed: 2,
        }
    },
    created() {
        let _this = this;
        // 监听页面滚动
        window.addEventListener("scroll", function() {
            var scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
            if (scrollTop > 200) {
                _this.showToTop = true
            } else {
                _this.showToTop = false
            }

            // 只有不在最顶部时才会生效
            if (scrollTop > 10) {
                // console.log(_this.scrollData);
                if (_this.scrollData.length == _this.responseSpeed) {
                    if (
                        _this.scrollData[0] <
                        _this.scrollData[_this.responseSpeed - 1]
                    ) {
                        // 下划，屏幕往上走，隐藏
                        _this.topNavShow = false;
                    }
                    if (
                        _this.scrollData[0] >
                        _this.scrollData[_this.responseSpeed - 1]
                    ) {
                        // 上划，屏幕往下走，显示
                        _this.topNavShow = true;
                    }
                }
                if (_this.scrollData.length >= _this.responseSpeed) {
                    _this.scrollData = [];
                } else {
                    _this.scrollData.push(scrollTop);
                }
            } else {
                _this.topNavShow = true;
            }
        });
    },
    methods: {
        init() {
            this.current = this.$route.path.slice(1)
        },
        topTopBtn() {
            this.scollTop()
        },
        // 返回顶部
        scollTop() {
            //  设置一个定时器
            const upRoll = setInterval(() => {
                const top = document.documentElement.scrollTop // 每次获取页面被卷去的部分
                const speed = Math.ceil(top / 10) // 每次滚动多少 （步长值）
                if (document.documentElement.scrollTop > 0) {
                    document.documentElement.scrollTop -= speed // 不在顶部 每次滚动到的位置
                } else {
                    clearInterval(upRoll) // 回到顶部清除定时器
                }
            }, 20)
        }

    },
    watch: {
        $route(to, from) {
            this.init()
        }
    }
}
</script>
<style lang="scss" scoped>
.topBox {
    transition: all 1s;
}

.topBox.hide {
    top: -100px;
}

.tab-link.active {
    color: red;
}


::v-deep .nav-item.choose .nav-link {
    color: red;
}
</style>