<template>
    <div class="container-md">
        <div class="mainBox">
            <div><img src="@/assets/images/join/join_01.png" class="w-100"></div>
            <div><img src="@/assets/images/join/join_02.png" class="w-100"></div>
            <div class="joinWrap" v-for="item in jobList">
                <div class="row joinItem">
                    <div class="col-md-4 title"><span>{{item.title}}</span></div>
                    <div class="col-md-8 content"><span>{{item.content}}</span></div>
                </div>
                <div class="row mt-2">
                    <div class="col-12 remark text-right">{{item.remark}}</div>
                </div>
            </div>
            <div class="text-right" style="color:#5F5F5F;margin-bottom: 1rem;margin-top:-1rem;">
                请把您的简历加上大作以PDF形式发送到 &nbsp;&nbsp;<font color="#DB2416">635450917@qq.com</font>&nbsp;&nbsp;标题请注明应聘岗位+姓名+电话
            </div>
            <div><img src="@/assets/images/join/join_03.png" class="w-100"></div>
        </div>
    </div>
</template>
<script>
import { listJob, getJob } from "@/api/job";
export default {
    data() {
        return {
            text: 'join',
            // 查询参数
            queryParams: {
                pageNum: 1,
                pageSize: 50
            },
            jobList:[]
        }
    },

    mounted(){
        this.getList()
    },
    methods: {
        /** 查询招聘列表 */
        getList() {
            this.loading = true;
            listJob(this.queryParams).then(response => {
                this.jobList = response.rows;
                this.loading = false;
            });
        },
    },
    watch: {

    }
}
</script>
<style lang="scss" scoped>
.joinWrap {
    margin: 0 15px 2rem 15px;
    &:last-child{
        margin-bottom:0;
    }

}

.border {}

.joinItem {
    border: solid 2px #9B9A9B;

    &>div {
        padding: 1rem;
    }




    .title {
        background: #040000;
        color: #ffffff;
    }

    .content {
        color: #040000;
    }
}

.remark {
    color: #5F5F5F;
}
</style>