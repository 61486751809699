<template>
    <div class="mainBox">
        <div class="container-md mt-3 mb-5">
            <div class="text-center">
                <div class="spinner-border text-secondary" role="status" v-if="loading">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <h3 class="title text-center">{{form.title}}</h3>
            <h5 class="text-center mt-3">{{form.publishTime}}</h5>
            <div class="des mt-3 ql-editor" v-html="form.content"></div>
        </div>
    </div>
</template>
<script>
import _ from 'lodash'
import { listNews, getNews } from "@/api/news";

export default {
    components: {},
    data() {
        return {
            text: 'newsDetail',
            loading: false,
            baseUrl: process.env.VUE_APP_BASE_API,
            form:{
                title:'',
                createTime:'',
                content:'',
            }



        }
    },

    computed: {

    },
    mounted() {
        let id = this.$route.query.id || ''
        if (id) {
            this.getDetail(id)
        }

    },
    methods: {
        getDetail(id) {
            let _this = this
            _this.loading = true
            getNews(id).then(response => {
                _this.loading = false
                let data = response.data
                data.content = data.content.replace(/\<img/gi, '<img style="max-width:100%;" ')
                _this.form = data;
            });
        }

    },

}
</script>
<style>
   
    .des p{ font-size: 1.3em;}

 
</style>
<style lang="scss" scoped>
.newItem {
    .con {
        margin-top: 2rem;

        h4 {
            color: #E7380C;
        }

        .date {
            color: #FF9C9C;
        }

        .des {}
    }

    .imgBox {
        width: 100%;
        padding-top: 50%;
        position: relative;
        overflow: hidden;

        img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            top: 0;
        }
    }
}
</style>