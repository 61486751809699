<template>
  <div>
    <v-header></v-header>
    <keep-alive>
      <router-view v-if="$route.path=='/work'"></router-view>
    </keep-alive>
    <router-view v-if="$route.path!='/work'"></router-view>
    
    <v-footer></v-footer>
  </div>
</template>
<script>
import vHeader from '@/components/header.vue'
import vFooter from '@/components/footer.vue'
export default {
  data() {
    return {}
  },
  created() {},
  methods: {},
  components: {
    vHeader,
    vFooter
  }
}
</script>
<style >

</style>
